<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Список пользователей
            <v-select
                value=""
                :items="roleOptions"
                item-text="text"
                item-value="roleOptionCode"
                style="width: 40%"
                @change="onSelectChange($event)"></v-select>
            <div class="header-buttons">

              <div class="menu-button search-menu-button" @click="$router.push('/config/userEdit')">
                <v-icon color="primary">mdi-account-plus</v-icon>
              </div>
            </div>
          </h1>
          <table class="anketsRegister-table" style="width: 100%;table-layout: auto">
            <thead>
            <tr>
              <th @click="sortByField('username')" style="cursor: pointer">Имя пользователя</th>
              <th @click="sortByField('name')" style="cursor: pointer">Полное имя</th>
              <th @click="sortByField('primaryRole')" style="cursor: pointer">Роль</th>
              <th @click="sortByField('email')" style="cursor: pointer">Email</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="u in usersList" :key="'ssdds'+u.primaryRole+u.id">
              <td>
                {{ u.username }}
              </td>
              <td style="white-space: normal;">
                {{ u.name }}
              </td>
              <td>
                {{
                  u.primaryRole === 'ROLE_CZN_MANAGER'  ? 'Управляющий ЦЗН' : (u.primaryRole === 'ROLE_CZN_TERRITORIAL' ? 'Территориальный ЦЗН' : (u.primaryRole === 'ROLE_VNII' ? 'Сотрудник ВНИИ Труда' : (u.primaryRole === 'ROLE_REGIONAL_AGENCY' ? 'Региональный орган занятости' : 'Администратор системы')))
                }}
              </td>
              <td>
                {{ u.email ? u.email : '&mdash;' }}
              </td>
              <td>
                <div class="edit" @click="$router.push('/config/userEdit/' +u.id + '/' +  u.type)">
                  <v-icon color="#0033A0">mdi-note-edit-outline</v-icon>
                </div>
              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </table>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  props: {},
  components: {},
  data() {
    return {
      cznUsers: [],
      users: [],
      usersList: [],
      apiLoaded: false,
      errorText: null,
      sortAsc: false,
      curSortField: null,
      roleOptions: [
        {roleOptionCode: "", text: "Все роли"},
        {roleOptionCode: "ROLE_CZN_MANAGER", text: "Управляющие ЦЗН"},
        {roleOptionCode: "ROLE_CZN_TERRITORIAL", text: "Территориальные ЦЗН"},
        {roleOptionCode: "ROLE_ADMIN", text: "Администраторы"},
        {roleOptionCode: "ROLE_VNII", text: "Сотрудники ВНИИ Труда"},
        {roleOptionCode: "ROLE_REGIONAL_AGENCY", text: "Региональные органы занятости"}
      ]
    };
  },
  methods: {
    async loadUsers() {
      let req = await this.$getApi('/getUserList')
      if (req.ok) {
        this.users = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    showAllUsers() {
      this.usersList = []
      this.usersList = this.usersList.concat(this.users)
    },

    showManagerCznOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_CZN_MANAGER')
          this.usersList.push(u)
    },

    showTerritorialCznOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_CZN_TERRITORIAL')
          this.usersList.push(u)
    },

    showVniiOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_VNII')
          this.usersList.push(u)
    },

    showAdminOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_ADMIN')
          this.usersList.push(u)
    },

    showRegionalAgencyOnly() {
      this.usersList = []
      for (let u of this.users)
        if (u.primaryRole === 'ROLE_REGIONAL_AGENCY')
          this.usersList.push(u)
    },

    onSelectChange(opt){
      if (opt === '')
        this.showAllUsers()
      else if (opt === 'ROLE_CZN_MANAGER')
        this.showManagerCznOnly()
      else if (opt === 'ROLE_CZN_TERRITORIAL')
        this.showTerritorialCznOnly()
      else if (opt === 'ROLE_ADMIN')
        this.showAdminOnly()
      else if (opt === 'ROLE_VNII')
        this.showVniiOnly()
      else if (opt === 'ROLE_REGIONAL_AGENCY')
        this.showRegionalAgencyOnly()
    },

    sortByField(field){
      if(this.curSortField === field)
        this.sortAsc = !this.sortAsc
      if(this.sortAsc)
        this.usersList.sort((a, b) => (a[field] < b[field]) ? 1 : -1)
      else
        this.usersList.sort((a, b) => (a[field] > b[field]) ? 1 : -1)
      this.curSortField = field
    }
  },
  async beforeMount() {
    await this.loadUsers()
    this.showAllUsers()
    this.apiLoaded = true


    // let vniiUser = {
    //   name: 'Администратор системы',
    //   userName: 'admin',
    //   email: 'shevernev@yandex.ru',
    //   stopEmails: false,
    //   role: 'ROLE_ADMIN'
    // }
    //let req = await this.$postApi('/saveVniiUser', vniiUser)
    //console.log(req)
  }
};
</script>
